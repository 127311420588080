<template>
  <div class="data-contain">
    <div class="add-box">
      <Row>
        <Col span="2" offset="22">
          <div class="member-oprate" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">
            <a class="new-doc fr" @click='clickAdd'>添加信息</a>
          </div>
        </Col>
      </Row>
    </div>

    <div class="table-box">
      <Table :columns="columns" :data="illessList" ref="table" @on-selection-change="handleSelect"></Table>

      <Row>
        <Col span="12">
          <div class="table-oprate">
            <!-- <Checkbox>全选</Checkbox> -->
            <Button style="background: #fff;" @click="handleAllRemove" v-show="power.indexOf('会员管理--删除会员信息权限') > -1">删除</Button>
          </div>
        </Col>
        <Col span="12">
          <span class="records">共{{ totalSize }}条记录</span>
        </Col>
      </Row>
    </div>

    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="totalSize" :current="sickData.page" :page-size="sickData.size" @on-change="changePage"></Page>
      </div>
    </div>

    <!-- Modal -->
    <Modal :title="title" v-model="sickModal">
      <Form :model="addSickData" :label-width="115">
        <Form-item label="手术时间：">
          <Date-picker type="date" placeholder="选择手术时间" v-model="sure_time" @on-change="changeDate"></Date-picker>
        </Form-item>
        <Form-item label="手术名称：">
          <Input placeholder="请输入手术名称" v-model="addSickData.name"></Input>
        </Form-item>
        <Form-item label="术后恢复情况：">
          <Input type="textarea" v-model="addSickData.describe" :maxlength="120" placeholder="请输入..."></Input>
          <p class="text">注：最多只能输入120字</p>
        </Form-item>
      </Form>
      <div slot="footer">
        <div class="symbol-box tac">
          <button type="button" class="ivu-btn ivu-btn-primary" @click="cancelModal"><span>取消</span></button>
          <button type="button" class="ivu-btn ivu-btn-success" @click="saveData"><span>保存</span></button>
        </div>
      </div>
    </Modal>
  </div>

</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			power: [],
			totalSize: 0,
			illessList: [],
			sickData: {
				member_id: '',
				monitor_id: '',
				page: 1,
				size: 10,
			},
			deleteItem: {
				ids: [],
			},
			sickNameList: [],
			title: '添加手术史信息',
			sickModal: false,
			sure_time: '',
			addSickData: {
				name: '',
				sure_time: '',
				describe: '',
			},
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '上传时间', key: 'create_time', align: 'center' },
				{ title: '名称', key: 'name', align: 'center' },
				{ title: '外伤手术史时间', key: 'sure_time', align: 'center' },
				{ title: '术后恢复情况', key: 'describe', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
							return h('div', [
								h('a', {
									class: 'icon_edit',
									on: {
										click: () => {
											this.sickModal = true;
											this.title = '修改手术史信息';
											this.sickData.monitor_id = this.illessList[params.index].id;
											MemberService.operationQuery(this.sickData).then((data) => {
												this.addSickData.name = data.list[0].name;
												this.addSickData.describe = data.list[0].describe;
												this.sure_time = this.addSickData.sure_time = data.list[0].sure_time;
											});
										},
									},
								}),
								h('a', {
									class: 'icon_del',
									on: {
										click: () => {
											this.$Modal.confirm({
												title: '提醒',
												content: '确定删除吗',
												onOk: () => {
													this.handleSingleRemove(params.index);
												},
											});
										},
									},
								}),
							]);
						} else {
							return h('div', [
								h('a', {
									class: 'icon_edit',
									style: {
										marginRight: 0,
									},
									on: {
										click: () => {
											this.sickModal = true;
											this.title = '修改手术史信息';
											this.sickData.monitor_id = this.illessList[params.index].id;
											MemberService.operationQuery(this.sickData).then((data) => {
												this.addSickData.name = data.list[0].name;
												this.addSickData.describe = data.list[0].describe;
												this.sure_time = this.addSickData.sure_time = data.list[0].sure_time;
											});
										},
									},
								}),
							]);
						}
					},
				},
			],
		};
	},
	computed: {
		userInfo() {
			return this.$store.getters.userInfo;
		},
		memberId() {
			return this.$route.params.member_id;
		},
	},
	created() {
		this.power = this.userInfo.power;
		this.getSickList();
		this.getSickMessage();
	},
	methods: {
		// 获取手术史信息详情
		getSickList() {
			this.sickData.member_id = this.memberId;
			MemberService.operationQuery(this.sickData).then((data) => {
				this.illessList = data.list;
				this.totalSize = data.row_size;
			});
		},
		// 获取疾病名称列表
		getSickMessage() {
			MemberService.sickMessage().then((data) => {
				this.sickNameList = data;
			});
		},
		changePage(page) {
			this.sickData.page = page;
			this.getSickList();
		},
		// table勾选
		handleSelect(selection) {
			this.deleteItem.ids = [];
			selection.forEach((item) => {
				this.deleteItem.ids.push(item.id);
			});
		},
		// 删除单个疾病信息
		handleSingleRemove(index) {
			let req = [this.illessList[index].id];
			MemberService.operationDel({ id_str: JSON.stringify(req) }).then(() => {
				this.getSickList();
				this.$Message.warning('删除成功');
			});
		},
		// 删除疾病信息
		handleAllRemove() {
			if (this.deleteItem.ids.length === 0) {
				this.$Message.warning('请先勾选要删除的数据');
			} else {
				this.$Modal.confirm({
					title: '提醒',
					content: '确定删除所选数据吗',
					onOk: () => {
						this.handleConfirmDelete();
					},
				});
			}
		},
		// 确认删除
		handleConfirmDelete() {
			MemberService.operationDel({ id_str: JSON.stringify(this.deleteItem.ids) }).then(() => {
				this.deleteItem.ids = [];
				this.getSickList();
				this.$Message.warning('删除成功');
			});
		},
		// 添加信息
		clickAdd() {
			this.title = '添加手术史信息';
			this.sure_time = '';
			this.addSickData.name = '';
			this.addSickData.describe = '';
			this.addSickData.sure_time = '';
			this.sickModal = true;
		},
		cancelModal() {
			this.sickModal = false;
			this.addSickData.name = '';
			this.addSickData.describe = '';
			this.addSickData.sure_time = '';
		},
		// 验证
		validateData() {
			if (!this.addSickData.sure_time) {
				this.$Message.warning('请选择手术时间');
				return false;
			} else if (!this.addSickData.name) {
				this.$Message.warning('请输入手术名称');
				return false;
			} else if (!this.addSickData.describe) {
				this.$Message.warning('请输入术后恢复情况');
				return false;
			} else {
				return true;
			}
		},
		// 添加疾病数据
		saveData() {
			if (this.validateData()) {
				if (this.title === '添加手术史信息') {
					delete this.addSickData.id;
					this.addSickData.member_id = this.memberId;
				} else {
					delete this.addSickData.member_id;
					this.addSickData.id = this.sickData.monitor_id;
				}
				MemberService.operationAdd(this.addSickData).then(() => {
					this.sickModal = false;
					this.sickData.monitor_id = '';
					this.$Message.success('保存成功');
					this.getSickList();
				});
			}
		},
		changeDate(value) {
			this.addSickData.sure_time = value;
		},
	},
};
</script>
<style lang="css" scoped>
.text {color: #999;}
</style>
